import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
export const _frontmatter = {
  "title": "Building an Invisible Grid",
  "date": "2017-10-16",
  "promo": "grids",
  "description": "Learn how to see your invisible CSS Grid while you build it.",
  "color": "#433eb0"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is what a 12 column, 6 row CSS Grid looks like:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/invisible-grid.png",
        "alt": "Invisible CSS Grid"
      }}></img></p>
    <p>{`Compare that with a 5 column, 4 row Grid:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/invisible-grid.png",
        "alt": "Invisible CSS Grid"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Uhh... they look the same. And empty. Where are the columns and rows?`}</p>
    </blockquote>
    <p>{`Grids are the future of UI - they're `}<a parentName="p" {...{
        "href": "/post/grid-for-responsive-layout/"
      }}>{`perfect for responsive`}</a>{` and `}<a parentName="p" {...{
        "href": "/post/grid-beats-flexbox-full-page-layout/"
      }}>{`full page layouts`}</a>{`. But there's one slight catch: `}<strong parentName="p">{`you can't actually see them`}</strong>{`.`}</p>
    <p>{`Remember the story of Rapunzel? The prince excitedly climbed the tall tower to see his beloved Rapunzel, only to find the wicked witch in her place. The prince was so shocked and frightened that he fell off the tower into some thorns, which of course blinded him because that's what thorns do to princes' eyes.`}</p>
    <p>{`You're the prince in this story. You've found your true love of layout (CSS Grid), but the wicked witch (spec writers?) have made it so you can't `}<em parentName="p">{`see`}</em>{` your new sweetheart.`}</p>
    <p>{`We're not at all used to working in the dark like this. We've always been able to style everything: floated divs, absolute positioned spans, even table rows & columns. But not Grids. A Grid only lets us see the content that goes inside of it, not the Grid itself. But as it turns out, being able to visualize your Grid while you build it is pretty important!`}</p>
    <h2>{`See the Grid`}</h2>
    <p>{`The clever folks at Mozilla realized that building layouts in the dark was going to be a real struggle. So they built an amazing tool into Firefox called the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Tools/Page_Inspector/How_to/Examine_grid_layouts"
      }}>{`Grid Inspector`}</a>{`. `}</p>
    <p>{`Here's what our first 12 column, 6 row Grid looks like as visualized by the Firefox Grid Inspector.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/not-invisible-grid.png",
        "alt": "Visible CSS Grid using Firefox Grid Inspector"
      }}></img></p>
    <p>{`This tool allows us to "see" the grid by rendering lines in the same positions as our `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines/"
      }}>{`Grid Lines`}</a>{`. It also renders any grid gaps and grid areas you've defined.`}</p>
    <p>{`Check out this 30 second clip where I used this awesome tool to visualize Link's inventory Grid in the `}<a parentName="p" {...{
        "href": "https://gedd.ski/build/zelda/"
      }}>{`Zelda UI`}</a>{`.`}</p>
    <YouTube id="7zBpd3wWsnU" start={782} end={819} mdxType="YouTube" />
    <p>{`It would have been next to impossible to get such an accurate Grid without being able to see it.`}</p>
    <h2>{`Get Them Tears`}</h2>
    <p>{`After falling from the tower, the prince searched blindly for his lost love. At last he heard Rapunzel's voice and was reunited with her. While holding him she cried, and her tears fell into his eyes. Naturally, this restored his sight and everything was just perfect.`}</p>
    <p>{`Don't try to build things blind. Let `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/developer/"
      }}>{`Firefox`}</a>{` cry into your blind eyes so you can finally see your Grids and build amazing UIs - happily ever after.`}</p>
    <div className="note">
NOTE: No metaphors were harmed in the making of this blog post.
    </div>
    <p>{`Update: Chrome 62 just got a very basic Grid highlighter that shows up when you hover over the element in the dev tools. It's not nearly as good as the full inspector in Firefox, but is a decent start.`}</p>
    <p>{`In my next post I'll show you how to `}<a parentName="p" {...{
        "href": "/post/grid-inspector"
      }}>{`build your own custom Grid Inspector`}</a>{` as a fun side project and to help you better understand how this awesome new tool works.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      